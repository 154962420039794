.switcher {
	display: inline-flex;
	align-items: center;
	height: 30px;
	margin-top: 8px;
	color: #2196F3;
    border: #434651 solid 1px;
    border-radius: 10px;
}
.controls-container {
    display: flex;
    justify-content: space-between; /* This spreads out the children to each end */
    align-items: center; /* This centers the children vertically */
}

.switcher {
    display: inline-flex;
    align-items: center;
    /* Your other styles */
}

/* Your other styles */

.chart-title {
    font-size: 30px; /* Large size for prominence */
    font-family: 'Arial', sans-serif; /* Choose a font-family that fits your design */
    padding: 5px 5px; /* Optional: Add some padding for a better visual */
}

.legend-text {
	font-size: 20px; /* Large size for prominence */
    font-family: 'Arial', sans-serif; /* Choose a font-family that fits your design */
    padding: 10px 5px; /* Optional: Add some padding for a better visual */
}

.switcher-item {
	cursor: pointer;
	text-decoration: none;
	display: inline-block;
	padding: 6px 8px;
	font-size: 14px;
	color: #D1D4DC;
	background-color: transparent;
	margin-right: 8px;
	border: none;
	border-radius: 4px;
	outline: none;
}

.switcher-item:hover {
	background-color: #f2f3f5;
}

.switcher-active-item {
	text-decoration: none;
	cursor: default;
	color: #262b3e;
}

.switcher-active-item,
.switcher-active-item:hover {
	background-color: #2B2E38;
    color: #E6E6E8;
}